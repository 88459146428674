import type { App } from 'vue';
import { ElMessage } from 'element-plus'

/**
 * 一键复制指令
 * @directive <el-button v-copy="copyText" type="primary" style="margin-top: 15px;">复制</el-button>
 */
export function copyDirective(app: App) {
    app.directive('copy', {
        mounted(el, binding) {
            el.$value = binding.value
            el.handler = async () => {
                if (!el.$value) {
                    ElMessage.warning("无复制内容")
                    return
                }
                try {
                    await navigator.clipboard.writeText(el.$value)
                    ElMessage.success("复制成功")
                } catch {
                    ElMessage.error("复制失败")
                }
            }
            el.addEventListener('click', el.handler)
        },
        updated(el, binding) {
            el.$value = binding.value
        },
        unmounted(el) {
            el.removeEventListener('click', el.handler)
        }
    });
}
