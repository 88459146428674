import type { App } from 'vue';
import { formatDate } from '/@/utils/formatTime';

const Time = {
	// 获取当前时间戳
	getUnix() {
		return Date.now();
	},
	// 获取今天0点0分0秒的时间戳
	getTodayUnix() {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		return date.getTime();
	},
	// 获取今年1月1日0点0秒的时间戳
	getYearUnix() {
		const date = new Date();
		date.setMonth(0, 1);
		date.setHours(0, 0, 0, 0);
		return date.getTime();
	},
	// 获取标准年月日
    getLastDate(time: number) {
		const date = new Date(time);
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${date.getFullYear()}-${month}-${day}`;
	},
	// 转换时间
    getFormateTime(timestamp: number) {
		const now = this.getUnix();
		const today = this.getTodayUnix();
		const timer = (now - timestamp) / 1000;
		let tip = '';

		if (timer <= 0) {
			tip = '刚刚';
		} else if (Math.floor(timer / 60) <= 0) {
			tip = '刚刚';
		} else if (timer < 3600) {
			tip = `${Math.floor(timer / 60)}分钟前`;
		} else if (timer >= 3600 && timestamp >= today) {
			tip = `${Math.floor(timer / 3600)}小时前`;
		} else if (timer / 86400 <= 31) {
			tip = `${Math.ceil(timer / 86400)}天前`;
		} else {
			tip = this.getLastDate(timestamp);
		}
		return tip;
	}
};

/**
 * 时间转换指令，如设置'tip'修饰符将会转换成相对时间,并且每60秒自动更新
 * @directive <el-tag v-time="1630117968295" format="yyyy-MM-dd hh:mm:ss"></el-tag>
 * @directive <el-tag v-time.tip="time1"></el-tag>
 */
export function timeDirective(app: App) {
	app.directive('time', {
		mounted(el, binding) {
			let { value, modifiers } = binding;
			if (!value) {
				return;
			}
			if (value.toString().length === 10) {
				value *= 1000;
			}
			if (modifiers.tip) {
				el.innerHTML = Time.getFormateTime(value);
				el.__timeout__ = setInterval(() => {
					el.innerHTML = Time.getFormateTime(value);
				}, 60000);
			} else {
				const format = el.getAttribute('format') || undefined;
                el.innerHTML = formatDate(value, format);
			}
		},
		unmounted(el) {
			if (el.__timeout__) {
				clearInterval(el.__timeout__);
			}
		}
	});
}
