import { watch } from 'vue';
import { isArray } from '/@/utils/is';
import { FieldNamesProps } from '/@/components/proTable/interface';
import _ from 'lodash';
import * as F from 'futil';
import { verifyNumber } from './validate';
import { DynamicUpdateModel } from '../api';

const mode = import.meta.env.VITE_ROUTER_MODE;

/**
 * @description 判断数据类型
 * @param {*} val 需要判断类型的数据
 * @returns {String}
 */
export function isType(val: any) {
	if (val === null) return 'null';
	if (typeof val !== 'object') return typeof val;
	else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
}

/**
 * @description 生成唯一 uuid
 * @returns {String}
 */
export function generateUUID() {
	let uuid = '';
	for (let i = 0; i < 32; i++) {
		let random = (Math.random() * 16) | 0;
		if (i === 8 || i === 12 || i === 16 || i === 20) uuid += '-';
		uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
	}
	return uuid;
}

/**
 * 判断两个对象是否相同
 * @param {Object} a 要比较的对象一
 * @param {Object} b 要比较的对象二
 * @returns {Boolean} 相同返回 true，反之 false
 */
export function isObjectValueEqual(a: { [key: string]: any }, b: { [key: string]: any }) {
	if (!a || !b) return false;
	let aProps = Object.getOwnPropertyNames(a);
	let bProps = Object.getOwnPropertyNames(b);
	if (aProps.length != bProps.length) return false;
	for (let i = 0; i < aProps.length; i++) {
		let propName = aProps[i];
		let propA = a[propName];
		let propB = b[propName];
		if (!b.hasOwnProperty(propName)) return false;
		if (propA instanceof Object) {
			if (!isObjectValueEqual(propA, propB)) return false;
		} else if (propA !== propB) {
			return false;
		}
	}
	return true;
}

/**
 * 对比待更新的数据，返回有改动的字段名
 * @param oldObj 旧数据
 * @param newObj 新数据
 * @param callback 回调，返回改动行的表名，改动行的主键和改动的字段名，只传 field 则判为主表字段
 * @returns { result: string[], childResult: Record<string, string[]> } 返回已更新字段的明细
 */
export function diffObjects(oldObj: any, newObj: any, callback?: (path: string[], item: any) => { name: string; id: number; field: string } | undefined) {
	// 改动过的主表字段名
	const result: string[] = [];
	// 改动过的子表字段名，结构为 { 子表字段名: { id: 改动行的主键, value: 改动的字段名数组 } }
	let childResult: Record<string, Record<number, string[]>> | null = null;
	const addChildResult = (name: string, id: number, field: string) => {
		// 先初始化
		if (childResult == null) childResult = {};
		if (name != '' && childResult[name] == undefined) childResult[name] = {};
		if (id > 0 && childResult[name][id] == undefined) childResult[name][id] = [];
		// 写入 childResult
		if (field != '') childResult[name][id].push(_.upperFirst(field));
	}
	// 对比对象差异，返回差异数据数组
	const diffData = F.diffArray(oldObj, newObj);
	// 遍历差异数据数组，差异数据结构为 [{ field: 'd.e', from: undefined, to: 5 },{ field: 'price', from: 20, to: undefined }]
	diffData.forEach((item: any) => {
		// 如果字段名不为空
		if (item.field.length == 0) return;
		// 拆分字段路径
		const path = item.field.split('.');
		const pathLength = path.length;
		if (pathLength == 0) return;
		// 先回调处理
		const r = callback?.(path, item);
		// 如果回调有返回值
		if (r != null) {
			// 子表名为空
			if (r.name == null || r.name == '') {
				// 字段名为空
				if (r.field != null && r.field != '')
					// 跳过空值
					return;
				else {
					// 如果没有 name ，直接 push 到 result
					result.push(_.upperFirst(r.field));
					return;
				}
			}

			// 数据完整，写入 childResult
				const name = _.upperFirst(r.name);
			if (r.field != null && r.field != '' && r.id != null) {
				addChildResult(name, r.id, r.field);
				return;
			}else{
				// 只有 name，表示子表有新增或删除
				addChildResult(name, 0, '');
			}
		}
		// 如果回调为空，通用处理
		// 字段路径只有一级，直接 push 到 result
		if (pathLength == 1) {
			result.push(_.upperFirst(path[0]));
		} else {
			// 一级以上的字段路径，处理子表字段
			const name = _.upperFirst(path[0]);
			// 路径第二级是否是数字
			const isArr = verifyNumber(path[1]);
			// 如果是 子对象 类型 d.e （路径第二级不是数字），或者是源类型数组 [1,2,3]，直接 push 到 result
			if (!isArr || (isArr && pathLength == 2)) {
				result.push(name);
			} else {
				// 如果是 子表 类型 d.0.e （路径第二级是数字），处理子表字段
				// 如果是删除操作，直接跳过
				if (path[2] == 'isDeleted') {
					addChildResult(name, 0, '');
					return;
				}
				// 如果是主键操作，直接跳过
				if (path[2] == 'id') return;
				// 如果是乐观锁操作，直接跳过
				if (path[2] == 'ver') return;
				// 找主键
				const cid = newObj[path[0]][path[1]]['id'] as number;
				// 如果没有主键，跳过
				if (cid == undefined || cid == 0) {
					addChildResult(name, 0, '');
					return;
				}

				addChildResult(name, path[1], path[2]);
			}
		}
	});

	// 子对象多行修改后可能有多个相同的主字段名，uniq 去重
	return { result: _.uniq(result), childResult };
}

interface DiffItem {
	field: string;
	from: any;
	to: any;
  }

  interface GroupedDiff {
	[key: string]: DiffItem[];
  }

export function groupDiffs(diffData: DiffItem[]): GroupedDiff {
	const groupedDiff: GroupedDiff = {};

	diffData.forEach((item) => {
		const path = item.field.split('.');
		const groupName = getGroupName(path);

		if (!groupedDiff[groupName]) {
			groupedDiff[groupName] = [];
		}
		groupedDiff[groupName].push(item);
	});

	return groupedDiff;
}

function getGroupName(path: string[]): string {
	const pathLength = path.length;
	if (pathLength >= 2) {
		// 复杂对象数组
		if (pathLength >= 3 && !isNaN(Number(path[1]))) {
			return `${path[0]}_${path[1]}`;
		}

		// 单个子对象或源类型数组对象
		return path[0];
	}

	// 主对象
	return 'main';
}

/**
 * 构建动态更新参数模型
 * @param oldObj 旧数据
 * @param newObj 新数据
 * @param callback 回调，返回改动行的表名，改动行的主键和改动的字段名，只传 field 则判为主表字段
 * @returns { DynamicUpdateModel } 返回 动态更新参数模型
 */
export function buildDynamicUpdateModel(oldObj: any, newObj: any): DynamicUpdateModel {
	// 初始化返回值
	const result: DynamicUpdateModel = { data: {}, cudData: undefined };
	if (result.cudData == null) result.cudData = {};
	// 对比对象差异，返回差异数据数组
	const diffData = F.diffArray(oldObj, newObj);
	console.log('diffData',diffData)
	// 差异分组
	const groupedDiff = groupDiffs(diffData);
	// 遍历差异分组
	for (const groupName in groupedDiff) {
		// 如果是主表
		if (groupName == 'main') {
			groupedDiff[groupName].forEach((item) => {
				if (item.field == 'id' || item.field == 'ver') return;
				result.data[_.upperFirst(item.field)] = newObj[item.field];
			});
			continue;
		}
		// 如果不包含下划线，是子对象
		if (!groupName.includes('_')) {
			const r = {} as { [key: string]: object; };
			groupedDiff[groupName].forEach((item) => {
				const path = item.field.split('.');
				if (path[1] == 'id' || path[1] == 'ver') return;
				r[_.upperFirst(path[1])] = newObj[groupName][path[1]];
			});
			// 如果 r 为空，跳过
			if (Object.keys(r).length > 0) {
				const upperName = _.upperFirst(groupName);
				if (result.cudData[upperName] == null) result.cudData[upperName] = {};
				if (result.cudData[upperName].updateData == null) result.cudData[upperName].updateData = [];
				r.Id = newObj[groupName].id;
				r.Ver = newObj[groupName].ver;
				result.cudData[upperName].updateData = [r];
			};
			continue;
		}
		// 剩下的定为复杂对象数组
		// 拆分组名
		const groupNamePath = groupName.split('_');
		// 子表名
		const childName = groupNamePath[0];
		// 子表名大写
		const upperChildName = _.upperFirst(childName);
		// 行数
		const rowIndex = Number(groupNamePath[1]);
		// 当前行更改的字段
		const diff = groupedDiff[groupName];
		// 是否是删除
		const isDeleted = diff.find((item) => item.field.endsWith('.isDeleted') && item.to == true);
		if (isDeleted) {
			// 初始化
			if (result.cudData[upperChildName] == null) result.cudData[upperChildName] = {};
			if (result.cudData[upperChildName].deleteIds == null) result.cudData[upperChildName].deleteIds = [];
			// 如果有 id
			if (newObj[childName][rowIndex].id && newObj[childName][rowIndex].id > 0)
				result.cudData[upperChildName].deleteIds.push(newObj[childName][rowIndex].id);
			continue;
		}
		// 判断是否新增
		const isNew = newObj[childName][rowIndex].id === 0;
		if (isNew) {
			// 初始化
			if (result.cudData[upperChildName] == null) result.cudData[upperChildName] = {};
			if (result.cudData[upperChildName].addData == null) result.cudData[upperChildName].addData = [];
			if (newObj[childName][rowIndex])
				result.cudData[upperChildName].addData.push(newObj[childName][rowIndex]);
			continue;
		}
		// 修改
		const updateData = {} as { [key: string]: object; };
		diff.forEach((item) => {
			const path = item.field.split('.');
			if (path[2] == 'id' || path[2] == 'ver') return;
			updateData[_.upperFirst(path[2])] = newObj[childName][rowIndex][path[2]];
		});
		// 如果 修改明细 为空，跳过
		if (Object.keys(updateData).length > 0) {
			// 初始化
			if (result.cudData[upperChildName] == null) result.cudData[upperChildName] = {};
			if (result.cudData[upperChildName].updateData == null) result.cudData[upperChildName].updateData = [];
			updateData.Id = newObj[childName][rowIndex].id;
			updateData.Ver = newObj[childName][rowIndex].ver;
			result.cudData[upperChildName].updateData?.push(updateData);
		};
	}

	// 有修改时加入必要数据
	if (Object.keys(result.data).length > 0 || (result.cudData != null && Object.keys(result.cudData).length > 0)){
		result.data.Id = newObj.id;
		result.data.Ver = newObj.ver;
	}
	return result;
}

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @returns {Number}
 */
export function randomNum(min: number, max: number): number {
	let num = Math.floor(Math.random() * (min - max) + max);
	return num;
}

/**
 * @description 获取当前时间对应的提示语
 * @returns {String}
 */
export function getTimeState() {
	let timeNow = new Date();
	let hours = timeNow.getHours();
	if (hours >= 6 && hours <= 10) return `早上好 ⛅`;
	if (hours >= 10 && hours <= 14) return `中午好 🌞`;
	if (hours >= 14 && hours <= 18) return `下午好 🌞`;
	if (hours >= 18 && hours <= 24) return `晚上好 🌛`;
	if (hours >= 0 && hours <= 6) return `凌晨好 🌛`;
}

/**
 * @description 获取浏览器默认语言
 * @returns {String}
 */
export function getBrowserLang() {
	let browserLang = navigator.language;
	let defaultBrowserLang = '';
	if (['cn', 'zh', 'zh-cn'].includes(browserLang.toLowerCase())) {
		defaultBrowserLang = 'zh';
	} else {
		defaultBrowserLang = 'en';
	}
	return defaultBrowserLang;
}

/**
 * @description 获取不同路由模式所对应的 url + params
 * @returns {String}
 */
export function getUrlWithParams() {
	const url = {
		hash: location.hash.substring(1),
		history: location.pathname + location.search,
	};
	return url[mode];
}

/**
 * @description 使用递归扁平化菜单，方便添加动态路由
 * @param {Array} menuList 菜单列表
 * @returns {Array}
 */
export function getFlatMenuList(menuList: Menu.MenuOptions[]): Menu.MenuOptions[] {
	let newMenuList: Menu.MenuOptions[] = JSON.parse(JSON.stringify(menuList));
	return newMenuList.flatMap((item) => [item, ...(item.children ? getFlatMenuList(item.children) : [])]);
}

/**
 * @description 使用递归过滤出需要渲染在左侧菜单的列表 (需剔除 isHide == true 的菜单)
 * @param {Array} menuList 菜单列表
 * @returns {Array}
 * */
export function getShowMenuList(menuList: Menu.MenuOptions[]) {
	let newMenuList: Menu.MenuOptions[] = JSON.parse(JSON.stringify(menuList));
	return newMenuList.filter((item) => {
		item.children?.length && (item.children = getShowMenuList(item.children));
		return !item.meta?.isHide;
	});
}

/**
 * @description 使用递归找出所有面包屑存储到 pinia/vuex 中
 * @param {Array} menuList 菜单列表
 * @param {Array} parent 父级菜单
 * @param {Object} result 处理后的结果
 * @returns {Object}
 */
export const getAllBreadcrumbList = (menuList: Menu.MenuOptions[], parent = [], result: { [key: string]: any } = {}) => {
	for (const item of menuList) {
		result[item.path] = [...parent, item];
		if (item.children) getAllBreadcrumbList(item.children, result[item.path], result);
	}
	return result;
};

/**
 * @description 使用递归处理路由菜单 path，生成一维数组 (第一版本地路由鉴权会用到，该函数暂未使用)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} menuPathArr 菜单地址的一维数组 ['**','**']
 * @returns {Array}
 */
export function getMenuListPath(menuList: Menu.MenuOptions[], menuPathArr: string[] = []): string[] {
	for (const item of menuList) {
		if (typeof item === 'object' && item.path) menuPathArr.push(item.path);
		if (item.children?.length) getMenuListPath(item.children, menuPathArr);
	}
	return menuPathArr;
}

/**
 * @description 递归查询当前 path 所对应的菜单对象 (该函数暂未使用)
 * @param {Array} menuList 菜单列表
 * @param {String} path 当前访问地址
 * @returns {Object | null}
 */
export function findMenuByPath(menuList: Menu.MenuOptions[], path: string): Menu.MenuOptions | null {
	for (const item of menuList) {
		if (item.path === path) return item;
		if (item.children) {
			const res = findMenuByPath(item.children, path);
			if (res) return res;
		}
	}
	return null;
}

/**
 * @description 使用递归过滤需要缓存的菜单 name (该函数暂未使用)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} keepAliveNameArr 缓存的菜单 name ['**','**']
 * @returns {Array}
 * */
export function getKeepAliveRouterName(menuList: Menu.MenuOptions[], keepAliveNameArr: string[] = []) {
	menuList.forEach((item) => {
		item.meta.isKeepAlive && item.name && keepAliveNameArr.push(item.name);
		item.children?.length && getKeepAliveRouterName(item.children, keepAliveNameArr);
	});
	return keepAliveNameArr;
}

/**
 * @description 格式化表格单元格默认值 (el-table-column)
 * @param {Number} row 行
 * @param {Number} col 列
 * @param {*} callValue 当前单元格值
 * @returns {String}
 * */
export function formatTableColumn(row: number, col: number, callValue: any) {
	// 如果当前值为数组，使用 / 拼接（根据需求自定义）
	if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '--';
	return callValue ?? '--';
}

/**
 * @description 处理 ProTable 值为数组 || 无数据
 * @param {*} callValue 需要处理的值
 * @returns {String}
 * */
export function formatValue(callValue: any) {
	// 如果当前值为数组，使用 / 拼接（根据需求自定义）
	if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '--';
	return callValue ?? '--';
}

/**
 * @description 处理 prop 为多级嵌套的情况，返回的数据 (列如: prop: user.name)
 * @param {Object} row 当前行数据
 * @param {String} prop 当前 prop
 * @returns {*}
 * */
export function handleRowAccordingToProp(row: { [key: string]: any }, prop: string) {
	if (!prop.includes('.')) return row[prop] ?? '--';
	prop.split('.').forEach((item) => (row = row[item] ?? '--'));
	return row;
}

/**
 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
 * @param {String} prop 当前 prop
 * @returns {String}
 * */
export function handleProp(prop: string) {
	const propArr = prop.split('.');
	if (propArr.length == 1) return prop;
	return propArr[propArr.length - 1];
}

/**
 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
 * @param {any} callValue 当前单元格值
 * @param {Array} enumData 字典列表
 * @param {FieldNamesProps} fieldNames label && value && children 的 key 值
 * @param {String} type 过滤类型（目前只有 tag）
 * @returns {String}
 * */
export function filterEnum(callValue: any, enumData: EnumItem[] | EnumItem, fieldNames?: FieldNamesProps, type?: 'tag'): string {
	const value = fieldNames?.value ?? 'value';
	const label = fieldNames?.label ?? 'label';
	const children = fieldNames?.children ?? 'children';
	// 判断 enumData 是否为数组
	const isArray = Array.isArray(enumData);
	const filterData = isArray ? findItemNested(enumData, callValue, value, children) : enumData;
	// 判断是否输出的结果为 tag 类型
	if (type == 'tag') {
		return filterData?.tagType ?? 'primary';
	} else {
		return filterData ? (isArray ? filterData[label] : filterData[callValue]) : '--';
	}
}

interface EnumItem {
	[key: string]: any;
	children?: EnumItem[];
}
/**
 * @description 递归查找 callValue 对应的 enum 值
 * @param {EnumItem[]} enumData 枚举数据
 * @param {any} callValue 当前单元格值
 * @param {string} valueKey value 的 key 值
 * @param {string} childrenKey children 的 key 值
 * @returns {EnumItem | null}
 */
export function findItemNested(enumData: EnumItem[], callValue: any, valueKey: string, childrenKey: string): EnumItem | null {
	for (const item of enumData) {
		if (String(item[valueKey]) === String(callValue)) {
			return item;
		}
		if (item[childrenKey]) {
			const found = findItemNested(item[childrenKey] as EnumItem[], callValue, valueKey, childrenKey);
			if (found) {
				return found;
			}
		}
	}
	return null;
}

/**
 * @description 等待 editRef 完成初始化
 * */
export async function waitForEditRef(editRef: any) {
	// 使用 Promise 来等待 editRef 准备好
	await new Promise((resolve, reject) => {
		// 设置一个超时，以防万一 editRef 永远不会被设置
		const timeoutId = setTimeout(() => {
			reject(new Error('Timeout waiting for editRef'));
		}, 5000); // 等待 5 秒

		// 监听 editRef 的变化
		const unwatch = watch(editRef, (newVal) => {
			if (newVal) {
				clearTimeout(timeoutId); // 清除超时
				unwatch(); // 取消监听
				resolve(undefined); // 解决 Promise
			}
		});
	});
}

/**
 * 处理请求错误并返回错误信息
 * @param {any} e 错误对象
 * @param {(errors: Record<string, string[]>) => void} handleErrors 处理表单验证错误的函数
 */
export const handleRequestError = (e: any, handleErrors?: (errors: Record<string, string[]>) => void) => {
	let errorMessage = '';
	if (!e || !e.errors) {
		errorMessage = '出现未知错误';
	} else if (typeof e.errors === 'string') {
		errorMessage = e.errors;
	} else if (Array.isArray(e.errors)) {
		errorMessage = e.errors.join(', ');
	} else if (typeof e.errors === 'object' && e.errors !== null) {
		if (handleErrors) {
			handleErrors(e.errors);
		} else {
			let errorString = '';
			for (const key in e.errors) {
				if (Array.isArray((e.errors as Record<string, any>)[key])) {
					errorString += `${key}: ${(e.errors as Record<string, any>)[key].join(', ')}, `;
				} else {
					errorString += `${key}: ${(e.errors as Record<string, any>)[key]}, `;
				}
			}
			// 去掉最后一个逗号
			errorMessage = errorString.slice(0, -2);
		}
	} else if (e.message) {
		errorMessage = e.message;
	} else {
		errorMessage = '操作失败' + JSON.stringify(e);
	}

	return errorMessage;
};

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
	const comp = component as any;
	comp.install = (app: any) => {
		app.component(comp.name || comp.displayName, component);
		if (alias) {
			app.config.globalProperties[alias] = component;
		}
	};
	return component as T & Plugin;
};

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
	return str.replace(/([A-Z])/g, '-$1').toLowerCase();
};

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
	if (!str) return '';
	return str.replace(/\-(\w)/g, (_, letter: string) => {
		return letter.toUpperCase();
	});
};

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
	return str.replace(/([A-Z])/g, '-$1').toLowerCase();
};

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
	dom.style.setProperty(prop, val);
};

export const getCssVar = (prop: string, dom = document.documentElement) => {
	return getComputedStyle(dom).getPropertyValue(prop);
};

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
	if (ary.findIndex) {
		return ary.findIndex(fn);
	}
	let index = -1;
	ary.some((item: T, i: number, ary: Array<T>) => {
		const ret: T = fn(item, i, ary);
		if (ret) {
			index = i;
			return ret;
		}
	});
	return index;
};

export const trim = (str: string) => {
	return str.replace(/(^\s*)|(\s*$)/g, '');
};

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string): string {
	if (!time) return '';

	const date = new Date(time);
	if (isNaN(date.getTime())) return ''; // 检查日期是否有效

	const o: { [key: string]: number } = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'H+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
		'q+': Math.floor((date.getMonth() + 3) / 3),
		S: date.getMilliseconds(),
	};

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(/(y+)/, (match) => (date.getFullYear() + '').substr(4 - match.length));
	}

	for (const k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			fmt = fmt.replace(new RegExp(`(${k})`), (match) => (match.length === 1 ? o[k].toString() : ('00' + o[k]).slice(-match.length)));
		}
	}

	return fmt;
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
	const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
		const r: number = (Math.random() * 16) | 0;
		const v: number = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString();
	});
	return str;
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
	return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
	const formData = new FormData();
	Object.keys(obj).forEach((key) => {
		formData.append(key, obj[key]);
	});
	return formData;
}
