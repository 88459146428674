import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { DicCategoryApiFactory, DicDetailData, DicInfoData } from '/@/api/api';

/**
 * 字典列表
 * @methods setDictList 设置字典列表
 */
export const useDictList = defineStore('dictList', () => {
	const dictList = ref<DicInfoData[]>();

	/**
	* 全部字典明细对象
	*/
	const dictItemObject = computed((): Record<string, Record<string, DicDetailData>> => {
		const dic: Record<string, Record<string, DicDetailData>> = {};
		dictList.value?.forEach((item) => {
			dic[item.code] = {};
			item.dicDataList?.forEach((detail) => {
				dic[item.code][detail.value] = detail;
			});
		});
		return dic;
	});

	/**
	 * 全部字典明细列表
	 */
	const dictItemArray = computed((): Record<string, DicDetailData[]> => {
		const dic: Record<string, DicDetailData[]> = {};
		dictList.value?.forEach((item) => {
			dic[item.code] = item.dicDataList ?? [];
		});
		return dic;
	});

	/**
	 * 刷新字典
	 */
	async function refreshAsync() {
		try {
			const res = await DicCategoryApiFactory().getDicCategoryList();
			if (res.errors) {
				console.error('获取字典失败', res.errors);
				return;
			}
			if (res.data === undefined || res.data.length === 0) {
				console.error('获取字典失败', '返回数据为空');
				return;
			}
			dictList.value = res.data;
		} catch (error) {
			console.error('刷新字典时发生错误:', error);
		}
	}

	return { dictList, refreshAsync, dictItemObject, dictItemArray };
});
